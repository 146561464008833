<template>
  <v-container fluid class="px-0">
    <v-card>
      <h1 class="ms-4">DPTO CAPTACIÓN  – SEGUIMIENTO LEAD</h1>
      <v-divider></v-divider>
      <v-data-table 
      @click:row="pushRoute" fixed-header :items="leadsContacto" :headers="contactoHeaders" item-key="idLead"
        :single-select="true" :loading="loading" :footer-props="{
              showFirstLastPage: true,
              showCurrentPage: true,
              itemsPerPageOptions: [15, -1],
            }" checkbox-color="secondary" style="cursor:pointer;width: min-content !important">
        <template v-slot:body.prepend="{ headers }">
          <TableFilters :headers="headers" :items="leadsContacto" v-model="inlineFiltersContacto"></TableFilters>
        </template>
          <template v-slot:item.n="{ item, index}">
            <td style="white-space: nowrap !important;padding-left: 22px !important;padding-right: 22px !important">{{
            index+1 }}</td>
          </template>
          <template v-slot:item.espacio="{ item, index}">
            <td style="white-space: nowrap !important;padding-left: 22px !important;padding-right: 22px !important" :title="item.ciudadCat">{{
            item.espacio }}</td>
          </template>
          <template v-slot:item.cola="{ item, index}">
            <td style="white-space: nowrap !important;padding-left: 22px !important;padding-right: 22px !important;font-size: x-large;">{{
            item.cola }}</td>
          </template>
          <template v-slot:item.calidad="{ item, index}">
            <td style="white-space: nowrap !important;padding-left: 22px !important;padding-right: 22px !important;text-align: center;">{{
            item.calidad
            }}</td>
          </template>
          <template v-slot:item.fechaEv="{ item, index}">
            <td style="white-space: nowrap !important;padding-left: 22px !important;padding-right: 22px !important;text-align: center;">{{
            item.fechaEv ? new Date(item.fechaEv).toLocaleDateString('esp', {day:'numeric', month:'short', year :'numeric' }).toLocaleUpperCase() : 'POR DEFINIR' 
            }}<v-icon v-if="leadsContacto.filter(l => l.fechaEv == item.fechaEv).length > 1" color="warning">mdi-alert</v-icon></td>
          </template>
          <template v-slot:item.auxEmail1="{ item, index}">
            <td :title="(item.email1nota || '')" :class="item.email1contesta != null ? (item.email1contesta ? 'success--text' : 'error--text') : 'text-center'" :style="'background-color: ' + ( item.email1contesta != null ? (item.email1contesta ? 'var(--v-success-lighten5)' : 'var(--v-error-lighten5)') : 'var(--v-secondary-lighten5)' )+ ';font-weight:bold;white-space: nowrap !important;padding-left: 22px !important;padding-right: 22px !important'">{{
            item.auxEmail1 ? item.auxEmail1 : '-'
            }}</td>
          </template>
          <template v-slot:item.auxEmail2="{ item, index}">
            <td :title="(item.email2nota || '')" :class="item.email2contesta != null ? (item.email2contesta ? 'success--text' : 'error--text') : 'text-center'" :style="'background-color: ' + ( item.email2contesta != null ? (item.email2contesta ? 'var(--v-success-lighten5)' : 'var(--v-error-lighten5)') : 'var(--v-secondary-lighten5)' )+ ';font-weight:bold;white-space: nowrap !important;padding-left: 22px !important;padding-right: 22px !important'">{{
            item.auxEmail2 ? item.auxEmail2 : '-'
            }}</td>
          </template>
          <template v-slot:item.auxEmail3="{ item, index}">
            <td :title="(item.email3nota || '')" :class="item.email3contesta != null ? (item.email3contesta ? 'success--text' : 'error--text') : 'text-center'" :style="'background-color: ' + ( item.email3contesta != null ? (item.email3contesta ? 'var(--v-success-lighten5)' : 'var(--v-error-lighten5)') : 'var(--v-secondary-lighten5)' )+ ';font-weight:bold;white-space: nowrap !important;padding-left: 22px !important;padding-right: 22px !important'">{{
            item.auxEmail3 ? item.auxEmail3 : '-'
            }}</td>
          </template>
          <template v-slot:item.auxCall1="{ item, index}">
            <td :title="(item.call1nota || '')" :class="item.call1contesta != null ? (item.call1contesta ? 'success--text' : 'error--text') : 'text-center'" :style="'background-color: ' + ( item.call1contesta != null ? (item.call1contesta ? 'var(--v-success-lighten5)' : 'var(--v-error-lighten5)') : 'var(--v-secondary-lighten5)' )+ ';font-weight:bold;white-space: nowrap !important;padding-left: 22px !important;padding-right: 22px !important'">{{
            (item.auxCall1 ||'-')
            }}</td>
          </template>
          <template v-slot:item.auxCall2="{ item, index}">
            <td :title="(item.call2nota || '')" :class="item.call2contesta != null ? (item.call2contesta ? 'success--text' : 'error--text') : 'text-center'" :style="'background-color: ' + ( item.call2contesta != null ? (item.call2contesta ? 'var(--v-success-lighten5)' : 'var(--v-error-lighten5)') : 'var(--v-secondary-lighten5)' )+ ';font-weight:bold;white-space: nowrap !important;padding-left: 22px !important;padding-right: 22px !important'">{{
            (item.auxCall2 ||'-')
            }}</td>
          </template>
          <template v-slot:item.auxCall3="{ item, index}">
            <td :title="(item.call3nota || '')" :class="item.call3contesta != null ? (item.call3contesta ? 'success--text' : 'error--text') : 'text-center'" :style="'background-color: ' + ( item.call3contesta != null ? (item.call3contesta ? 'var(--v-success-lighten5)' : 'var(--v-error-lighten5)') : 'var(--v-secondary-lighten5)' )+ ';font-weight:bold;white-space: nowrap !important;padding-left: 22px !important;padding-right: 22px !important'">{{
            (item.auxCall3 ||'-')
            }}</td>
          </template>
          <template v-slot:item.auxWhats1="{ item, index}">
            <td :title="(item.whats1nota || '')" :class="item.whats1contesta != null ? (item.whats1contesta ? 'success--text' : 'error--text') : 'text-center'" :style="'background-color: ' + ( item.whats1contesta != null ? (item.whats1contesta ? 'var(--v-success-lighten5)' : 'var(--v-error-lighten5)') : 'var(--v-secondary-lighten5)' )+ ';font-weight:bold;white-space: nowrap !important;padding-left: 22px !important;padding-right: 22px !important'">{{
            (item.auxWhats1 ||'-')
            }}</td>
          </template>
          <template v-slot:item.auxWhats2="{ item, index}">
            <td :title="(item.whats2nota || '')" :class="item.whats2contesta != null ? (item.whats2contesta ? 'success--text' : 'error--text') : 'text-center'" :style="'background-color: ' + ( item.whats2contesta != null ? (item.whats2contesta ? 'var(--v-success-lighten5)' : 'var(--v-error-lighten5)') : 'var(--v-secondary-lighten5)' )+ ';font-weight:bold;white-space: nowrap !important;padding-left: 22px !important;padding-right: 22px !important'">{{
            (item.auxWhats2 ||'-')
            }}</td>
          </template>
          <template v-slot:item.auxWhats3="{ item, index}">
            <td :title="(item.whats3nota || '')" :class="item.whats3contesta != null ? (item.whats3contesta ? 'success--text' : 'error--text') : 'text-center'" :style="'background-color: ' + ( item.whats3contesta != null ? (item.whats3contesta ? 'var(--v-success-lighten5)' : 'var(--v-error-lighten5)') : 'var(--v-secondary-lighten5)' )+ ';font-weight:bold;white-space: nowrap !important;padding-left: 22px !important;padding-right: 22px !important'">{{
            (item.auxWhats3 ||'-')
            }}</td>
          </template>
      </v-data-table>
    </v-card>
    <v-card class="mt-5">
      <h1 class="ms-4">DPTO COMERCIAL – SEGUIMIENTO VENTA</h1>
      <v-divider></v-divider>
      <v-data-table 
      :items="leadsComercial" :headers="comercialHeaders" item-key="idLead"
        :single-select="true" :loading="loading" :footer-props="{
              showFirstLastPage: true,
              showCurrentPage: true,
              itemsPerPageOptions: [-1,0],
            }" checkbox-color="secondary" style="cursor:pointer;width: min-content !important">
        <template v-slot:header="props" >
          <thead>
            <tr>
              <th colspan="10" style="border-right:1px solid var(--v-secondary-lighten5);position:sticky;left:0" class="text-center">DPTO CAPTACIÓN</th>
              <th colspan="7" style="border-right:1px solid var(--v-secondary-lighten5)" class="text-center">ESTADO VENTA</th>
              <th :colspan="comercialBiggestCall+comercialBiggestEmail+comercialBiggestWhats" class="text-center" :style="'border-right:1px solid var(--v-secondary-lighten5);' + (comercialBiggestCall ? '' : 'display:none')">SEGUIMIENTO</th>
            </tr>
          </thead>
        </template>
        <template v-slot:body.prepend="{ headers }">
          <TableFilters :headers="headers" :items="leadsComercial" v-model="inlineFiltersComercial"></TableFilters>
        </template>
          <template v-slot:item="{ item, index}">
            <tr @click="pushRoute(item)">
              <td :class="($vuetify.theme.dark ? 'dark' : 'light') + ' st-1'" style="z-index: 1000;white-space: nowrap !important;min-width: 46px;width: 46px !important;padding-left: 22px !important;padding-right: 22px !important">{{ index+1 }}</td>
              <td :class="($vuetify.theme.dark ? 'dark' : 'light') + ' st-2'" style="z-index: 1000;white-space: nowrap !important;min-width: 120px;width: 120px !important;padding-left: 22px !important;padding-right: 22px !important;font-size: x-large;">{{ item.cola }}</td>
              <td :class="($vuetify.theme.dark ? 'dark' : 'light') + ' st-3'" style="z-index: 1000;white-space: nowrap !important;min-width: 120px;width: 120px !important;padding-left: 22px !important;padding-right: 22px !important">{{ item.mes }}</td>
              <td :class="($vuetify.theme.dark ? 'dark' : 'light') + ' st-4'" style="z-index: 1000;white-space: nowrap !important;min-width: 120px;width: 120px !important;padding-left: 22px !important;padding-right: 22px !important">{{ item.origen }}</td>
              <td :class="($vuetify.theme.dark ? 'dark' : 'light') + ' st-5'" style="z-index: 1000;white-space: nowrap !important;min-width: 60px;width: 60px !important;padding-left: 22px !important;padding-right: 22px !important">{{ item.calidad }}</td>
              <td :class="($vuetify.theme.dark ? 'dark' : 'light') + ' st-6'" style="z-index: 1000;white-space: nowrap !important;min-width: 120px;width: 120px !important;padding-left: 22px !important;padding-right: 22px !important" :title="item.clientes">{{ item.clientes }}</td>
              <td :class="($vuetify.theme.dark ? 'dark' : 'light') + ' st-7'" style="z-index: 1000;white-space: nowrap !important;min-width: 150px;width: 150px !important;padding-left: 22px !important;padding-right: 22px !important">{{ item.fechaEv ? new Date(item.fechaEv).toLocaleDateString('esp', {day:'numeric', month:'short', year :'numeric' }).toLocaleUpperCase() : 'POR DEFINIR' }}</td>
              <td :class="($vuetify.theme.dark ? 'dark' : 'light') + ' st-8'" style="z-index: 1000;white-space: nowrap !important;min-width: 120px;width: 120px !important;padding-left: 22px !important;padding-right: 22px !important" :title="item.ciudadCat">{{ item.espacio }}</td>
              <td :class="($vuetify.theme.dark ? 'dark' : 'light') + ' st-9'" style="z-index: 1000;white-space: nowrap !important;min-width: 80px;width: 80px !important;padding-left: 22px !important;padding-right: 22px !important">{{ item.comensales }}</td>
              <td style="z-index: 1000;white-space: nowrap !important;min-width: 150px;width: 150px !important;padding-left: 22px !important;padding-right: 22px !important" :class="(new Date(item.fechaCita) > new Date() ? 'error--text' : '') + ' ' + (($vuetify.theme.dark ? 'dark' : 'light') + ' st-10')">{{ item.auxFechaCita }}</td>
              <td style="white-space: nowrap !important;padding-left: 22px !important;padding-right: 22px !important;font-weight: bold" :class="['error--text','success--text'][item.responsabilidad-1]">{{ item.auxResponsabilidad }}</td>
              <td style="white-space: nowrap !important;padding-left: 22px !important;padding-right: 22px !important;font-weight: bold;" :class="['error--text','warning--text','success--text'][item.prevision]">{{ item.auxPrevision }}</td>
              <td :title="item.presupuestos[item.presupuestos.length-1]?.nota || ''" :style="'white-space: nowrap !important;padding-left: 22px !important;padding-right: 22px !important;font-weight:bold;background-color:' + (item.presupuestos[item.presupuestos.length-1] ? (['var(--v-error-lighten5)','var(--v-success-lighten5)'][item.presupuestos[item.presupuestos.length-1]?.estado-1]) : 'var(--v-secondary-lighten5)')" :class="['error--text','success--text'][item.presupuestos[item.presupuestos.length-1]?.estado-1] + ' ' + (item.presupuestos[item.presupuestos.length-1]?.fecha ? '' : 'text-center')">
                <div class="d-flex flex-column">
                  <span style="width:100%;text-align: center;">{{ item.presupuesto }}</span>
                  <span style="width:100%;margin-bottom: -5px;" class="d-flex justify-center">
                    <v-icon :key="i" v-for="(p,i) in item.presupuestos.length" style="max-height: 5px;max-width: 10px;" :color="['error','success'][item.presupuestos[item.presupuestos.length-1]?.estado-1]">mdi-circle-small</v-icon>
                  </span>
                </div>
              </td>
              <td :title="item.condiciones[item.condiciones.length-1]?.nota || ''" :style="'white-space: nowrap !important;padding-left: 22px !important;padding-right: 22px !important;font-weight:bold;background-color:' + (item.condiciones[item.condiciones.length-1] ? (['var(--v-error-lighten5)','var(--v-success-lighten5)'][item.condiciones[item.condiciones.length-1]?.estado-1]) : 'var(--v-secondary-lighten5)')" :class="['error--text','success--text'][item.condiciones[item.condiciones.length-1]?.estado-1] + ' ' + (item.condiciones[item.condiciones.length-1]?.fecha ? '' : 'text-center')">
                <div class="d-flex flex-column">
                  <span style="width:100%;text-align: center;">{{ item.condicion }}</span>
                  <span style="width:100%;margin-bottom: -5px;" class="d-flex justify-center">
                    <v-icon :key="i" v-for="(p,i) in item.condiciones.length" style="max-height: 5px;max-width: 10px;" :color="['error','success'][item.condiciones[item.condiciones.length-1]?.estado-1]">mdi-circle-small</v-icon>
                  </span>
                </div>
              </td>
              <td :title="item.visitas[item.visitas.length-1]?.nota || ''" :style="'white-space: nowrap !important;padding-left: 22px !important;padding-right: 22px !important;font-weight:bold;background-color:' + (item.visitas[item.visitas.length-1] ? (['var(--v-error-lighten5)','var(--v-success-lighten5)'][item.visitas[item.visitas.length-1]?.estado-1]) : 'var(--v-secondary-lighten5)')" :class="['error--text','success--text'][item.visitas[item.visitas.length-1]?.estado-1] + ' ' + (item.visitas[item.visitas.length-1]?.fecha ? '' : 'text-center')">
                <div class="d-flex flex-column">
                  <span style="width:100%;text-align: center;">{{ item.visita }}</span>
                  <span style="width:100%;margin-bottom: -5px;" class="d-flex justify-center">
                    <v-icon :key="i" v-for="(p,i) in item.visitas.length" style="max-height: 5px;max-width: 10px;" :color="['error','success'][item.visitas[item.visitas.length-1]?.estado-1]">mdi-circle-small</v-icon>
                  </span>
                </div>
              </td>
              <td :title="item.llamadas[item.llamadas.length-1]?.nota || ''" :style="'white-space: nowrap !important;padding-left: 22px !important;padding-right: 22px !important;font-weight:bold;background-color:' + (item.llamadas[item.llamadas.length-1] ? (['var(--v-error-lighten5)','var(--v-success-lighten5)'][item.llamadas[item.llamadas.length-1]?.estado-1]) : 'var(--v-secondary-lighten5)')" :class="['error--text','success--text'][item.llamadas[item.llamadas.length-1]?.estado-1] + ' ' + (item.llamadas[item.llamadas.length-1]?.fecha ? '' : 'text-center')">
                <div class="d-flex flex-column">
                  <span style="width:100%;text-align: center;">{{ item.llamada }}</span>
                  <span style="width:100%;margin-bottom: -5px;" class="d-flex justify-center">
                    <v-icon :key="i" v-for="(p,i) in item.llamadas.length" style="max-height: 5px;max-width: 10px;" :color="['error','success'][item.llamadas[item.llamadas.length-1]?.estado-1]">mdi-circle-small</v-icon>
                  </span>
                </div>
              </td>
              <td :title="item.otros[item.otros.length-1]?.nota || ''" :style="'white-space: nowrap !important;padding-left: 22px !important;padding-right: 22px !important;font-weight:bold;background-color:' + (item.otros[item.otros.length-1] ? (['var(--v-error-lighten5)','var(--v-success-lighten5)'][item.otros[item.otros.length-1]?.estado-1]) : 'var(--v-secondary-lighten5)')" :class="['error--text','success--text'][item.otros[item.otros.length-1]?.estado-1] + ' ' + (item.otros[item.otros.length-1]?.fecha ? '' : 'text-center')">
                <div class="d-flex flex-column">
                  <span style="width:100%;text-align: center;">{{ item.otro }}</span>
                  <span style="width:100%;margin-bottom: -5px;" class="d-flex justify-center">
                    <v-icon :key="i" v-for="(p,i) in item.otros.length" style="max-height: 5px;max-width: 10px;" :color="['error','success'][item.otros[item.otros.length-1]?.estado-1]">mdi-circle-small</v-icon>
                  </span>
                </div>
              </td>
              <td :title="item.callComercial[i-1]?.nota" :class="'text-center ' + ((item.callComercial[i-1]?.estado) != null ? (['error--text','success--text'][item.callComercial[i-1]?.estado]) : '')" :style="'white-space: nowrap !important;padding-left: 22px !important;padding-right: 22px !important;font-weight:bold;background-color:' + ((item.callComercial[i-1]?.estado) != null ? (['var(--v-error-lighten5)','var(--v-success-lighten5)'][item.callComercial[i-1]?.estado]) : 'var(--v-secondary-lighten5)')" v-for="i in comercialBiggestCall">
                {{ item.callComercial[i-1]?.fecha ? new Date((item.callComercial)[i-1]?.fecha).toLocaleDateString('esp',{ day:'numeric', month: 'short', year:'numeric' }).toLocaleUpperCase() : '-' }}
              </td>
              <td :title="item.emailComercial[i-1]?.nota" :class="'text-center ' + ((item.emailComercial[i-1]?.estado) != null ? (['error--text','success--text'][item.emailComercial[i-1]?.estado]) : '')" :style="'white-space: nowrap !important;padding-left: 22px !important;padding-right: 22px !important;font-weight:bold;background-color:' + ((item.emailComercial[i-1]?.estado) != null ? (['var(--v-error-lighten5)','var(--v-success-lighten5)'][item.emailComercial[i-1]?.estado]) : 'var(--v-secondary-lighten5)')" v-for="i in comercialBiggestEmail">
                {{ item.emailComercial[i-1]?.fecha ? new Date((item.emailComercial)[i-1]?.fecha).toLocaleDateString('esp',{ day:'numeric', month: 'short', year:'numeric' }).toLocaleUpperCase() : '-' }}
              </td>
              <td :title="item.whatsComercial[i-1]?.nota" :class="'text-center ' + ((item.whatsComercial[i-1]?.estado) != null ? (['error--text','success--text'][item.whatsComercial[i-1]?.estado]) : '')" :style="'white-space: nowrap !important;padding-left: 22px !important;padding-right: 22px !important;font-weight:bold;background-color:' + ((item.whatsComercial[i-1]?.estado) != null ? (['var(--v-error-lighten5)','var(--v-success-lighten5)'][item.whatsComercial[i-1]?.estado]) : 'var(--v-secondary-lighten5)')" v-for="i in comercialBiggestWhats">
                {{ item.whatsComercial[i-1]?.fecha ? new Date((item.whatsComercial)[i-1]?.fecha).toLocaleDateString('esp',{ day:'numeric', month: 'short', year:'numeric' }).toLocaleUpperCase() : '-' }}
              </td>
            </tr>
          </template>
      </v-data-table>
    </v-card>

    <v-card class="mt-5">
      <h1 class="ms-4">GANADOS</h1>
      <v-divider></v-divider>
      <v-data-table 
      @click:row="pushRoute" fixed-header :items="leadsGanados" :headers="ganadosHeaders" item-key="idLead"
        :single-select="true" :loading="loading" :footer-props="{
              showFirstLastPage: true,
              showCurrentPage: true,
              itemsPerPageOptions: [10, -1],
            }" checkbox-color="secondary" style="cursor:pointer;width: min-content !important">
        <template v-slot:body.prepend="{ headers }">
          <TableFilters :headers="headers" :items="leadsGanados" v-model="inlineFiltersGanados"></TableFilters>
        </template>
          <template v-slot:item.n="{ item, index}">
            <td style="white-space: nowrap !important;padding-left: 22px !important;padding-right: 22px !important">{{
            index+1 }}</td>
          </template>
          <template v-slot:item.espacio="{ item, index}">
            <td :title="item.ciudadCat" style="white-space: nowrap !important;padding-left: 22px !important;padding-right: 22px !important">{{
            item.espacio }}</td>
          </template>
          <template v-slot:item.fechaEv="{ item, index}">
            <td style="white-space: nowrap !important;padding-left: 22px !important;padding-right: 22px !important">{{
            item.fechaEv ? new Date(item.fechaEv).toLocaleDateString('esp', {day:'numeric', month:'short', year :'numeric' }).toLocaleUpperCase() : 'POR DEFINIR' }}</td>
          </template>
      </v-data-table>
    </v-card>

    <v-card class="mt-5">
      <h1 class="ms-4">PERDIDOS</h1>
      <v-divider></v-divider>
      <v-data-table 
      @click:row="pushRoute" fixed-header :items="leadsPerdidos" :headers="perdidosHeaders" item-key="idLead"
        :single-select="true" :loading="loading" :footer-props="{
              showFirstLastPage: true,
              showCurrentPage: true,
              itemsPerPageOptions: [10, -1],
            }" checkbox-color="secondary" style="cursor:pointer;width: min-content !important">
        <template v-slot:body.prepend="{ headers }">
          <TableFilters :headers="headers" :items="leadsPerdidos" v-model="inlineFiltersPerdidos"></TableFilters>
        </template>
          <template v-slot:item.n="{ item, index}">
            <td style="white-space: nowrap !important;padding-left: 22px !important;padding-right: 22px !important">{{
            index+1 }}</td>
          </template>
          <template v-slot:item.espacio="{ item, index}">
            <td :title="item.ciudadCat" style="white-space: nowrap !important;padding-left: 22px !important;padding-right: 22px !important">{{
            item.espacio }}</td>
          </template>
          <template v-slot:item.fechaEv="{ item, index}">
            <td style="white-space: nowrap !important;padding-left: 22px !important;padding-right: 22px !important">{{
            item.fechaEv ? new Date(item.fechaEv).toLocaleDateString('esp', {day:'numeric', month:'short', year :'numeric' }).toLocaleUpperCase() : 'POR DEFINIR' }}</td>
          </template>
      </v-data-table>
    </v-card>
    <v-card class="mt-5">
      <h1 class="ms-4">ELIMINADOS</h1>
      <v-divider></v-divider>
      <v-data-table 
      @click:row="pushRoute" fixed-header :items="leadsEliminados" :headers="eliminadosHeaders" item-key="idLead"
        :single-select="true" :loading="loading" :footer-props="{
              showFirstLastPage: true,
              showCurrentPage: true,
              itemsPerPageOptions: [10, -1],
            }" checkbox-color="secondary" style="cursor:pointer;width: min-content !important">
        <template v-slot:body.prepend="{ headers }">
          <TableFilters :headers="headers" :items="leadsEliminados" v-model="inlineFiltersEliminados"></TableFilters>
        </template>
          <template v-slot:item.n="{ item, index}">
            <td style="white-space: nowrap !important;padding-left: 22px !important;padding-right: 22px !important">{{
            index+1 }}</td>
          </template>
          <template v-slot:item.fechaEv="{ item, index}">
            <td style="white-space: nowrap !important;padding-left: 22px !important;padding-right: 22px !important">{{
            item.fechaEv ? new Date(item.fechaEv).toLocaleDateString('esp', {day:'numeric', month:'short', year :'numeric' }).toLocaleUpperCase() : 'POR DEFINIR' }}</td>
          </template>
      </v-data-table>
    </v-card>


    <div style="max-width: fit-content;position: fixed;right:20px; bottom: 20px;z-index:100000" bottom class="d-flex mt-2 ms-auto">
      <v-btn color="primary" fab @click="exportar" large class="me-4">
        <v-icon>mdi-microsoft-excel</v-icon>
      </v-btn>

      <v-btn color="primary" fab @click="addLead" large>
        <v-icon>mdi-plus</v-icon>
      </v-btn>
    </div>
  </v-container>
</template>

<script>
import { perColumnFilter } from "@/utils";
import FileSaver from 'file-saver';
import * as ExcelJS from "exceljs";

export default {
  components: {
    TableFilters: () => import("@/components/TableFilters.vue"),
  },
  data() {
    return {
      comercialBiggestCall: 0,
      comercialBiggestEmail: 0,
      comercialBiggestWhats: 0,
      loading: true,
      leadsContacto: [],
      leadsComercial: [],
      leadsGanados: [],
      leadsPerdidos: [],
      leadsEliminados: [],
      inlineFiltersComercial: {
        idLead: this.$route.query.filter
          ? `=${this.$route.query.filter}`
          : null,
      },
      inlineFiltersContacto: {
        idLead: this.$route.query.filter
          ? `=${this.$route.query.filter}`
          : null,
      },
      inlineFiltersGanados: {
        idLead: this.$route.query.filter
          ? `=${this.$route.query.filter}`
          : null,
      },
      inlineFiltersPerdidos: {
        idLead: this.$route.query.filter
          ? `=${this.$route.query.filter}`
          : null,
      },
      inlineFiltersEliminados: {
        idLead: this.$route.query.filter
          ? `=${this.$route.query.filter}`
          : null,
      },
      origen:[
          {title:"B.NET CAT", val : 1},
          {title:"B.NET PIS", val : 2},
          {title:"B.NET LAUS", val : 3},
          {title:"B.NET HOT", val : 4},
          {title:"B.NET MDP", val : 11},
          {title:"LAL DIR", val : 5},
          {title:"LAUS DIR", val : 6},
          {title:"WEB", val : 7},
          {title:"EMAIL", val : 8},
          {title:"TLF", val : 9},
          {title:"INTERNO", val : 10},
      ],
      prevision:[
        {title:"NEG", val : 0}, 
        {title:"NEU", val : 1},
        {title:"POS", val : 2},
      ],
      espacio:[
        {title: "PTE", val : 0},
        {title: "PISCIS", val : 1},
        {title: "HOTEL", val : 2}, 
        {title: "LALANNE", val : 3}, 
        {title: "LAUS", val : 4}, 
        {title: "PALACIO", val : 5}, 
        {title: "MAS DE PONENT", val : 16}, 
        {title: "LLOTJA", val : 8},
        {title: "VDV", val : 9}, 
        {title: "CATERING ARAGÓN", val : 6}, 
        {title: "CATERING CATALUÑA", val : 7}
      ],
      solicitud:[
          {title:"PTE", val : 1},
          {title:"ENVIADO", val : 2},
      ],
      estado:[
          {title:"TRANSFORMADA (CITA)", val : 1},
          {title:"GANADA", val : 4},
          {title:"PERDIDA", val : 2},
          {title:"ELIMINADA", val : 3},
      ],
      introduce:[
          {title:"MONICA", val : 4}, 
          {title:"LAURA", val : 1}, 
          {title:"BERTA", val : 2}, 
      ]
    };
  },
  mounted(){
    this.getLeads();
  },
  computed: {
    contactoHeaders() {
      return [
        { text: "Nº", value: "n",class: "text-no-wrap sticky-header text-center", sortable: false, align: "center", },
        { text: "COLA", value: "cola", class: "text-no-wrap sticky-header text-center", align: "center", },
        { text: "MES", value: "mes", class: "text-no-wrap sticky-header" },
        { text: "VÍA", value: "origen", dataType: "select", class: "text-no-wrap sticky-header" },
        { text: "Q", value: "calidad", dataType: "select", class: "text-no-wrap sticky-header" },
        { text: "NOMBRE", value: "clientes", class: "text-no-wrap sticky-header text-center", },
        { text: "BODA", value: "fechaEv", class: "text-no-wrap sticky-header text-center", },
        { text: "ESPACIO", value: "espacio", dataType: "select", class: "text-no-wrap sticky-header" },
        { text: "PAX", value: "comensales", class: "text-no-wrap sticky-header" },
        { text: "TELÉFONO", value: "tlf", class: "text-no-wrap sticky-header" },
        { text: "CORREO", value: "email", class: "text-no-wrap sticky-header" },
        { text: "EMAIL 1", value: "auxEmail1", class: "warning--text text-no-wrap sticky-header" },
        { text: "EMAIL 2", value: "auxEmail2", class: "warning--text text-no-wrap sticky-header" },
        { text: "EMAIL 3", value: "auxEmail3", class: "warning--text text-no-wrap sticky-header" },
        { text: "CALL 1", value: "auxCall1", class: "info--text text-no-wrap sticky-header" },
        { text: "CALL 2", value: "auxCall2", class: "info--text text-no-wrap sticky-header" },
        { text: "CALL 3", value: "auxCall3", class: "info--text text-no-wrap sticky-header" },
        { text: "WHATS 1", value: "auxWhats1", class: "error--text text-no-wrap sticky-header" },
        { text: "WHATS 2", value: "auxWhats2", class: "error--text text-no-wrap sticky-header" },
        { text: "WHATS 3", value: "auxWhats3", class: "error--text text-no-wrap sticky-header" },
        { text: "NOTA INICIAL", value: "notaInicial", class: "text-no-wrap sticky-header" },
      ]
        .filter(
          (h) =>
            (h.acceso == null || this.$root.acceso(h.acceso))
        )
        .map((header) => ({
          cellClass: "pa-1 text-no-wrap",
          filter: (value) =>
            perColumnFilter(
              value,
              this.inlineFiltersContacto[header.value],
              header.dataType
            ),
          dataType: "text",
          ...header,
        }));
    },
    comercialHeaders() {
      var d = [
        { text: "Nº", value: "n",headerClass: "st-1", class: "st-1 text-no-wrap sticky-header text-center", sortable: false, align: "center", },
        { text: "COLA", value: "cola", headerClass: "st-2", class: "st-2 text-no-wrap sticky-header text-center", align: "center", },
        { text: "MES", value: "mes", headerClass: "st-3", class: "st-3 text-no-wrap sticky-header" },
        { text: "VÍA", value: "origen", dataType: "select", headerClass: "st-4", class: "st-4 text-no-wrap sticky-header" },
        { text: "Q", value: "calidad", dataType: "select", headerClass: "st-5", class: "st-5 text-no-wrap sticky-header" },
        { text: "NOMBRE", value: "clientes", headerClass: "st-6", class: "st-6 text-no-wrap sticky-header text-center", },
        { text: "BODA", value: "fechaEv", headerClass: "st-7", class: "st-7 text-no-wrap sticky-header text-center", },
        { text: "ESPACIO", value: "espacio", dataType: "select", headerClass: "st-8", class: "st-8 text-no-wrap sticky-header" },
        { text: "PAX", value: "comensales", headerClass: "st-9", class: "st-9 text-no-wrap sticky-header" },
        { text: "CITA", value: "auxFechaCita", headerClass: "st-10", class: "st-10 text-no-wrap sticky-header" },
        { text: "RESPONSABILIDAD", value: "auxResponsabilidad", class: "text-no-wrap sticky-header" },
        { text: "PREVISIÓN", value: "prevision", class: "text-no-wrap sticky-header" },
        { text: "PPTO", value: "presupuesto", class: "text-no-wrap sticky-header" },
        { text: "COND. ESP", value: "condiciones", class: "text-no-wrap sticky-header" },
        { text: "VISITA ESP", value: "visita", class: "text-no-wrap sticky-header" },
        { text: "DARLES TIEMPO", value: "llamada", class: "text-no-wrap sticky-header" },
        { text: "OTROS", value: "otros", class: "text-no-wrap sticky-header" },
      ]
      for (let i = 0; i < this.comercialBiggestCall; i++) {
        d.push({ text: "LLAMADA " + (i+1), value: "call" + (i+1), class: "warning--text text-no-wrap sticky-header" })
      }
      for (let i = 0; i < this.comercialBiggestEmail; i++) {
        d.push({ text: "EMAIL " + (i+1), value: "email" + (i+1), class: "info--text text-no-wrap sticky-header" })
      }
      for (let i = 0; i < this.comercialBiggestWhats; i++) {
        d.push({ text: "WHATS " + (i+1), value: "whats" + (i+1), class: "error--text text-no-wrap sticky-header" })
      }

      return d.filter(
        (h) =>
          (h.acceso == null || this.$root.acceso(h.acceso))
      )
      .map((header) => ({
        cellClass: "pa-1 text-no-wrap",
        filter: (value) =>
          perColumnFilter(
            value,
            this.inlineFiltersComercial[header.value],
            header.dataType
          ),
        dataType: "text",
        ...header,
      }));
    },
    ganadosHeaders() {
      return [
        { text: "Nº", value: "n",class: "text-no-wrap sticky-header text-center", sortable: false, align: "center", },
        { text: "DÍAS COLA", value: "cola", class: "text-no-wrap sticky-header text-center", align: "center", },
        { text: "MES", value: "mes", class: "text-no-wrap sticky-header" },
        { text: "VÍA ENTRADA", value: "origen", dataType: "select", class: "text-no-wrap sticky-header" },
        { text: "LEAD CALIDAD", value: "calidad", dataType: "select", class: "text-no-wrap sticky-header" },
        { text: "NOMBRE", value: "clientes", class: "text-no-wrap sticky-header text-center", },
        { text: "FECHA EVENTO", value: "fechaEv", class: "text-no-wrap sticky-header text-center", },
        { text: "ESPACIO", value: "espacio", dataType: "select", class: "text-no-wrap sticky-header" },
        { text: "COMENSALES", value: "comensales", class: "text-no-wrap sticky-header" },
        { text: "TELÉFONO", value: "tlf", class: "text-no-wrap sticky-header" },
        { text: "CORREO", value: "email", class: "text-no-wrap sticky-header" },

        // { text: "CERCA CIERRE", value: "auxEmail1", class: "text-no-wrap sticky-header" },
        // { text: "EMAIL 2", value: "auxEmail2", class: "text-no-wrap sticky-header" },
        // { text: "CALL 1", value: "auxCall1", class: "text-no-wrap sticky-header" },
        // { text: "CALL 2", value: "auxCall2", class: "text-no-wrap sticky-header" },
        // { text: "CALL 3", value: "auxCall3", class: "text-no-wrap sticky-header" },
        // { text: "WHATS 1", value: "auxWhats1", class: "text-no-wrap sticky-header" },
        // { text: "WHATS 2", value: "auxWhats2", class: "text-no-wrap sticky-header" },
        // { text: "NOTA INICIAL", value: "notaInicial", class: "text-no-wrap sticky-header" },
      ]
        .filter(
          (h) =>
            (h.acceso == null || this.$root.acceso(h.acceso))
        )
        .map((header) => ({
          cellClass: "pa-1 text-no-wrap",
          filter: (value) =>
            perColumnFilter(
              value,
              this.inlineFiltersGanados[header.value],
              header.dataType
            ),
          dataType: "text",
          ...header,
        }));
    },
    perdidosHeaders() {
      return [
        { text: "Nº", value: "n",class: "text-no-wrap sticky-header text-center", sortable: false, align: "center", },
        { text: "DÍAS COLA", value: "cola", class: "text-no-wrap sticky-header text-center", align: "center", },
        { text: "MES", value: "mes", class: "text-no-wrap sticky-header" },
        { text: "VÍA ENTRADA", value: "origen", dataType: "select", class: "text-no-wrap sticky-header" },
        { text: "LEAD CALIDAD", value: "calidad", dataType: "select", class: "text-no-wrap sticky-header" },
        { text: "NOMBRE", value: "clientes", class: "text-no-wrap sticky-header text-center", },
        { text: "FECHA EVENTO", value: "fechaEv", class: "text-no-wrap sticky-header text-center", },
        { text: "ESPACIO", value: "espacio", dataType: "select", class: "text-no-wrap sticky-header" },
        { text: "COMENSALES", value: "comensales", class: "text-no-wrap sticky-header" },
        { text: "TELÉFONO", value: "tlf", class: "text-no-wrap sticky-header" },
        { text: "CORREO", value: "email", class: "text-no-wrap sticky-header" },
        { text: "MOTIVO", value: "perdida", class: "text-no-wrap sticky-header" },
        { text: "DETALLAR MOTIVO", value: "motivo", class: "text-no-wrap sticky-header" },

        // { text: "CERCA CIERRE", value: "auxEmail1", class: "text-no-wrap sticky-header" },
        // { text: "EMAIL 2", value: "auxEmail2", class: "text-no-wrap sticky-header" },
        // { text: "CALL 1", value: "auxCall1", class: "text-no-wrap sticky-header" },
        // { text: "CALL 2", value: "auxCall2", class: "text-no-wrap sticky-header" },
        // { text: "CALL 3", value: "auxCall3", class: "text-no-wrap sticky-header" },
        // { text: "WHATS 1", value: "auxWhats1", class: "text-no-wrap sticky-header" },
        // { text: "WHATS 2", value: "auxWhats2", class: "text-no-wrap sticky-header" },
        // { text: "NOTA INICIAL", value: "notaInicial", class: "text-no-wrap sticky-header" },
      ]
        .filter(
          (h) =>
            (h.acceso == null || this.$root.acceso(h.acceso))
        )
        .map((header) => ({
          cellClass: "pa-1 text-no-wrap",
          filter: (value) =>
            perColumnFilter(
              value,
              this.inlineFiltersPerdidos[header.value],
              header.dataType
            ),
          dataType: "text",
          ...header,
        }));
    },
    eliminadosHeaders() {
      return [
        { text: "Nº", value: "n",class: "text-no-wrap sticky-header text-center", sortable: false, align: "center", },
        { text: "DÍAS COLA", value: "cola", class: "text-no-wrap sticky-header text-center", align: "center", },
        { text: "MES", value: "mes", class: "text-no-wrap sticky-header" },
        { text: "VÍA ENTRADA", value: "origen", dataType: "select", class: "text-no-wrap sticky-header" },
        { text: "LEAD CALIDAD", value: "calidad", dataType: "select", class: "text-no-wrap sticky-header" },
        { text: "NOMBRE", value: "clientes", class: "text-no-wrap sticky-header text-center", },
        { text: "FECHA EVENTO", value: "fechaEv", class: "text-no-wrap sticky-header text-center", },
        { text: "ESPACIO", value: "espacio", dataType: "select", class: "text-no-wrap sticky-header" },
        { text: "COMENSALES", value: "comensales", class: "text-no-wrap sticky-header" },
        { text: "TELÉFONO", value: "tlf", class: "text-no-wrap sticky-header" },
        { text: "CORREO", value: "email", class: "text-no-wrap sticky-header" },
        { text: "MOTIVO", value: "eliminada", class: "text-no-wrap sticky-header" },
        { text: "DETALLAR MOTIVO", value: "motivo", class: "text-no-wrap sticky-header" },

        // { text: "CERCA CIERRE", value: "auxEmail1", class: "text-no-wrap sticky-header" },
        // { text: "EMAIL 2", value: "auxEmail2", class: "text-no-wrap sticky-header" },
        // { text: "CALL 1", value: "auxCall1", class: "text-no-wrap sticky-header" },
        // { text: "CALL 2", value: "auxCall2", class: "text-no-wrap sticky-header" },
        // { text: "CALL 3", value: "auxCall3", class: "text-no-wrap sticky-header" },
        // { text: "WHATS 1", value: "auxWhats1", class: "text-no-wrap sticky-header" },
        // { text: "WHATS 2", value: "auxWhats2", class: "text-no-wrap sticky-header" },
        // { text: "NOTA INICIAL", value: "notaInicial", class: "text-no-wrap sticky-header" },
      ]
        .filter(
          (h) =>
            (h.acceso == null || this.$root.acceso(h.acceso))
        )
        .map((header) => ({
          cellClass: "pa-1 text-no-wrap",
          filter: (value) =>
            perColumnFilter(
              value,
              this.inlineFiltersPerdidos[header.value],
              header.dataType
            ),
          dataType: "text",
          ...header,
        }));
    },
  },
  methods: {
    exportar() {
      const workbook = new ExcelJS.Workbook();
      const sheetCaptacion = workbook.addWorksheet('CAPTACIÓN');
      sheetCaptacion.getRow(1).alignment = { horizontal: 'center', wrapText: false };
      sheetCaptacion.getColumn(1).alignment = { horizontal: 'center', wrapText: false };
      sheetCaptacion.getColumn(5).alignment = { horizontal: 'center', wrapText: false };

      var r = []

      this.leadsContacto.forEach((lead,i) => {
        r.push(
          [
            (i + 1),
            lead.cola,
            lead.mes,
            lead.origen,
            lead.calidad,
            lead.clientes,
            lead.fechaEv ? new Date(lead.fechaEv).toLocaleDateString('esp', {day:'numeric', month:'short', year :'numeric' }).toLocaleUpperCase() : 'POR DEFINIR',
            lead.espacio,
            lead.comensales,
            lead.tlf,
            lead.email,
            (lead.auxEmail1 ||'-'),
            (lead.auxEmail2 ||'-'),
            (lead.auxCall1 || '-'),
            (lead.auxCall2 || '-'),
            (lead.auxCall3 || '-'),
            (lead.auxWhats1 || '-'),
            (lead.auxWhats2 || '-'),
            (lead.notaInicial || '')
          ]
        )
      });

      if(r.length > 0){
        sheetCaptacion.addTable({
          name: 'LeadsCaptacion',
          ref: 'B2',
          headerRow: true,
          totalsRow: false,
          style: {
            showRowStripes: true
          },
          columns:[
            { name: "Nº", filterButton: true},
            { name: "COLA", filterButton: true},
            { name: "MES", filterButton: true},
            { name: "VÍA", filterButton: true},
            { name: "Q", filterButton: true},
            { name: "NOMBRE", filterButton: true},
            { name: "BODA", filterButton: true},
            { name: "ESPACIO", filterButton: true},
            { name: "PAX", filterButton: true},
            { name: "TELÉFONO", filterButton: true},
            { name: "CORREO", filterButton: true},
            { name: "EMAIL 1", filterButton: true},
            { name: "EMAIL 2", filterButton: true},
            { name: "CALL 1", filterButton: true},
            { name: "CALL 2", filterButton: true},
            { name: "CALL 3", filterButton: true},
            { name: "WHATS 1", filterButton: true},
            { name: "WHATS 2", filterButton: true},
            { name: "NOTA INICIAL", filterButton: true},
          ],
          rows: r
        })
      }
      else{
        sheetCaptacion.getCell('B2').value = 'SIN DATOS'
      }

      const sheetComercial = workbook.addWorksheet('COMERCIAL');
      sheetComercial.getRow(1).alignment = { horizontal: 'center', wrapText: false };
      sheetComercial.getColumn(1).alignment = { horizontal: 'center', wrapText: false };
      sheetComercial.getColumn(5).alignment = { horizontal: 'center', wrapText: false };

      var r2 = []

      this.leadsComercial.forEach((lead,i) => {
        var row = 
        [
          (i + 1),
          lead.cola,
          lead.mes,
          lead.origen,
          lead.calidad,
          lead.clientes,
          lead.fechaEv ? new Date(lead.fechaEv).toLocaleDateString('esp', {day:'numeric', month:'short', year :'numeric' }).toLocaleUpperCase() : 'POR DEFINIR',
          lead.espacio,
          lead.comensales,
          lead.auxFechaCita,
          lead.auxResponsabilidad,
          lead.auxPrevision,
          lead.presupuesto,
          lead.condiciones,
          lead.visita,
          lead.llamada,
          lead.otros,
        ]
        for (let i = 0; i < this.comercialBiggestCall; i++) {
          row.push(lead.callComercial[i]?.fecha ? new Date((lead.callComercial)[i]?.fecha).toLocaleDateString('esp',{ day:'numeric', month: 'short', year:'numeric' }).toLocaleUpperCase() : '-')
        }
        for (let i = 0; i < this.comercialBiggestEmail; i++) {
          row.push(lead.emailComercial[i]?.fecha ? new Date((lead.emailComercial)[i]?.fecha).toLocaleDateString('esp',{ day:'numeric', month: 'short', year:'numeric' }).toLocaleUpperCase() : '-')
        }
        for (let i = 0; i < this.comercialBiggestWhats; i++) {
          row.push(lead.whatsComercial[i]?.fecha ? new Date((lead.whatsComercial)[i]?.fecha).toLocaleDateString('esp',{ day:'numeric', month: 'short', year:'numeric' }).toLocaleUpperCase() : '-')
        }
        r2.push(row)
      });
      var columns = [
        { name: "Nº", filterButton: true},
        { name: "COLA", filterButton: true},
        { name: "MES", filterButton: true},
        { name: "VÍA", filterButton: true},
        { name: "Q", filterButton: true},
        { name: "NOMBRE", filterButton: true},
        { name: "BODA", filterButton: true},
        { name: "ESPACIO", filterButton: true},
        { name: "PAX", filterButton: true},
        { name: "CITA", filterButton: true},
        { name: "RESPONSABILIDAD", filterButton: true},
        { name: "PREVISIÓN", filterButton: true},
        { name: "PPTO", filterButton: true},
        { name: "COND. ESP", filterButton: true},
        { name: "VISITA ESP", filterButton: true},
        { name: "DARLES TIEMPO", filterButton: true},
        { name: "OTROS", filterButton: true }
      ]
      for (let i = 0; i < this.comercialBiggestCall; i++) {
        columns.push({ name: "LLAMADA " + (i+1), filterButton: true})
      }
      for (let i = 0; i < this.comercialBiggestEmail; i++) {
        columns.push({ name: "EMAIL " + (i+1), filterButton: true})
      }
      for (let i = 0; i < this.comercialBiggestWhats; i++) {
        columns.push({ name: "WHATS " + (i+1), filterButton: true})
      }
      if(r2.length > 0){
        sheetComercial.addTable({
          name: 'LeadsComercial',
          ref: 'B2',
          headerRow: true,
          totalsRow: false,
          style: {
            showRowStripes: true
          },
          columns:columns,
          rows: r2
        })
      }
      else{
      sheetComercial.getCell('B2').value = 'SIN DATOS'
      }

      workbook.xlsx.writeBuffer()
      .then(buffer => FileSaver.saveAs(new Blob([buffer]), `LEADS - EXPORTADO EL ${(new Date(new Date().getTime() + (new Date().getTimezoneOffset()*-1)*60000).toLocaleDateString('esp', {day:'numeric', month:'short', year:'numeric'}))}.xlsx`))
      .catch(err => console.log('Error writing excel export', err))
    },
    pushRoute(e){
      this.$router.push({ name: 'datosLead', params: { idLead: e.idLead } })
    },
    async getLeads(){
      this.loading = true;
      const contacto = await axios({
        method: "GET",
        url: `${process.env.VUE_APP_API_URL}/leads/contacto`,
      });
      this.leadsContacto = contacto.data
      this.leadsContacto.forEach(lead => {
        lead.cola = (Math.floor((Math.abs(new Date() - new Date(lead.fecha))) / (1000 * 60 * 60 * 24)))
        lead.mes = new Date(lead.fecha).toLocaleDateString('esp', {month : 'short', year:'numeric'}).toLocaleUpperCase()
        lead.origen = (this.origen.find(o => o.val == lead.origen)?.title || null)
        lead.calidad = lead.calidad ? 'Sí' : 'No'
        lead.ciudadCat = lead.ciudadCat
        lead.espacio = lead.espacio != 6 || lead.espacio != 7 ? (this.espacio.find(o => o.val == lead.espacio)?.title) : (lead.ciudadCat || '')
        lead.auxEmail1 = lead.email1fecha ? new Date(lead.email1fecha).toLocaleDateString('esp', {day:'numeric', month:'short', year :'numeric' }).toLocaleUpperCase() : null
        lead.auxEmail2 = lead.email2fecha ? new Date(lead.email2fecha).toLocaleDateString('esp', {day:'numeric', month:'short', year :'numeric' }).toLocaleUpperCase() : null
        lead.auxEmail3 = lead.email3fecha ? new Date(lead.email3fecha).toLocaleDateString('esp', {day:'numeric', month:'short', year :'numeric' }).toLocaleUpperCase() : null
        lead.auxCall1 = lead.call1fecha ? new Date(lead.call1fecha).toLocaleDateString('esp', {day:'numeric', month:'short', year :'numeric' }).toLocaleUpperCase() : null
        lead.auxCall2 = lead.call2fecha ? new Date(lead.call2fecha).toLocaleDateString('esp', {day:'numeric', month:'short', year :'numeric' }).toLocaleUpperCase() : null
        lead.auxCall3 = lead.call3fecha ? new Date(lead.call3fecha).toLocaleDateString('esp', {day:'numeric', month:'short', year :'numeric' }).toLocaleUpperCase() : null
        lead.auxWhats1 = lead.whats1fecha ? new Date(lead.whats1fecha).toLocaleDateString('esp', {day:'numeric', month:'short', year :'numeric' }).toLocaleUpperCase() : null
        lead.auxWhats2 = lead.whats2fecha ? new Date(lead.whats2fecha).toLocaleDateString('esp', {day:'numeric', month:'short', year :'numeric' }).toLocaleUpperCase() : null
        lead.auxWhats3 = lead.whats3fecha ? new Date(lead.whats3fecha).toLocaleDateString('esp', {day:'numeric', month:'short', year :'numeric' }).toLocaleUpperCase() : null
      });

      this.leadsContacto.sort((a,b) => {
        if (a.cola > b.cola) return 1
        else if (a.cola < b.cola) return -1
        return 0
      })

      const comercial = await axios({
        method: "GET",
        url: `${process.env.VUE_APP_API_URL}/leads/comercial`,
      });
      this.leadsComercial = comercial.data
      this.leadsComercial.forEach(lead => {
        if(new Date() < new Date(lead.fechaCita)) lead.cola = 0
        else lead.cola = (Math.floor((Math.abs(new Date() - new Date(lead.fechaCita))) / (1000 * 60 * 60 * 24)))
        lead.mes = new Date(lead.fecha).toLocaleDateString('esp', {month : 'short', year:'numeric'}).toLocaleUpperCase()
        lead.origen = (this.origen.find(o => o.val == lead.origen)?.title || null)
        lead.calidad = lead.calidad ? 'Sí' : 'No'
        lead.ciudadCat = lead.ciudadCat
        lead.espacio = lead.espacio != 6 || lead.espacio != 7 ? (this.espacio.find(o => o.val == lead.espacio)?.title) : (lead.ciudadCat || '')
        lead.auxEstado = lead.estado ? this.estado.find(e=>e.val == lead.estado)?.title : null
        lead.auxFechaTransformacion = lead.fechaTransformacion ? new Date(lead.fechaTransformacion).toLocaleDateString('esp', { day:'numeric', month:'short', year :'numeric' }).toLocaleUpperCase() : null
        lead.auxFechaCita = lead.fechaCita ? new Date(lead.fechaCita).toLocaleDateString('esp', { day:'numeric', month:'short', year :'numeric' }).toLocaleUpperCase() : null
        lead.auxTomaDecision = lead.decisionPareja ? 'Sí' : 'No'
        lead.auxPrevision = ['NEGATIVA','NEUTRA','POSITIVA'][lead.prevision]
        lead.presupuestos = JSON.parse(lead.presupuestos)
        lead.condiciones = JSON.parse(lead.condiciones)
        lead.visitas = JSON.parse(lead.visitas)
        lead.llamadas = JSON.parse(lead.llamadas)
        lead.otros = JSON.parse(lead.otros)
        lead.presupuesto = lead.presupuestos[lead.presupuestos.length-1]?.fecha ? new Date(lead.presupuestos[lead.presupuestos.length-1].fecha).toLocaleDateString('esp', { day:'numeric', month:'short', year:'numeric' }).toLocaleUpperCase() : '-'
        lead.condicion = lead.condiciones[lead.condiciones.length-1]?.fecha ? new Date(lead.condiciones[lead.condiciones.length-1].fecha).toLocaleDateString('esp', { day:'numeric', month:'short', year:'numeric' }).toLocaleUpperCase() : '-'
        lead.visita = lead.visitas[lead.visitas.length-1]?.fecha ? new Date(lead.visitas[lead.visitas.length-1].fecha).toLocaleDateString('esp', { day:'numeric', month:'short', year:'numeric' }).toLocaleUpperCase() : '-'
        lead.llamada = lead.llamadas[lead.llamadas.length-1]?.fecha ? new Date(lead.llamadas[lead.llamadas.length-1].fecha).toLocaleDateString('esp', { day:'numeric', month:'short', year:'numeric' }).toLocaleUpperCase() : '-'
        lead.otro = lead.otros[lead.otros.length-1]?.fecha ? new Date(lead.otros[lead.otros.length-1].fecha).toLocaleDateString('esp', { day:'numeric', month:'short', year:'numeric' }).toLocaleUpperCase() : '-'
        lead.auxResponsabilidad = ["MASFARRE","PAREJA"][lead.responsabilidad-1]
        try {
          lead.emailComercial = JSON.parse(lead.emailComercial)
          if(lead.emailComercial.length > this.comercialBiggestEmail) this.comercialBiggestEmail = lead.emailComercial.length
        } catch (err) {
          lead.emailComercial = undefined
        }
        try {
          lead.callComercial = JSON.parse(lead.callComercial)
          if(lead.callComercial.length > this.comercialBiggestCall) this.comercialBiggestCall = lead.callComercial.length
        } catch (err) {
          lead.callComercial = undefined
        }
        try {
          lead.whatsComercial = JSON.parse(lead.whatsComercial)
          if(lead.whatsComercial.length > this.comercialBiggestWhats) this.comercialBiggestWhats = lead.whatsComercial.length
        } catch (err) {
          lead.whatsComercial = undefined
        }
      });

      this.leadsComercial.sort((a,b) => {
        var keyA = a.cola;
        var keyB = b.cola;
        if (keyA > keyB) return -1;
        if (keyA < keyB) return 1;
        
        keyA = new Date(a.fechaCita);
        keyB = new Date(b.fechaCita);
        if (keyA < keyB) return -1;
        if (keyA > keyB) return 1;

        return 0;
      })

      const ganados = await axios({
        method: "GET",
        url: `${process.env.VUE_APP_API_URL}/leads/ganados`,
      });
      this.leadsGanados = ganados.data
      this.leadsGanados.forEach(lead => {
        lead.cola = (Math.floor((Math.abs(new Date(lead.fechaTransformacion) - new Date(lead.fecha))) / (1000 * 60 * 60 * 24)))
        lead.mes = new Date(lead.fecha).toLocaleDateString('esp', {month : 'short', year:'numeric'}).toLocaleUpperCase()
        lead.origen = (this.origen.find(o => o.val == lead.origen)?.title || null)
        lead.ciudadCat = lead.ciudadCat
        lead.calidad = lead.calidad ? 'Sí' : 'No'
        lead.espacio = lead.espacio != 6 || lead.espacio != 7 ? (this.espacio.find(o => o.val == lead.espacio)?.title) : (lead.ciudadCat || '')
        lead.auxEstado = lead.estado ? this.estado.find(e=>e.val == lead.estado)?.title : null
        lead.auxFechaTransformacion = lead.fechaTransformacion ? new Date(lead.fechaTransformacion).toLocaleDateString('esp', {day:'numeric', month:'short', year :'numeric' }).toLocaleUpperCase() : null
        lead.nota = (lead.motivo || null)
      });

      const perdidos = await axios({
        method: "GET",
        url: `${process.env.VUE_APP_API_URL}/leads/perdidos`,
      });
      this.leadsPerdidos = perdidos.data
      this.leadsPerdidos.forEach(lead => {
        lead.cola = (Math.floor((Math.abs(new Date(lead.fechaTransformacion) - new Date(lead.fecha))) / (1000 * 60 * 60 * 24)))
        lead.mes = new Date(lead.fecha).toLocaleDateString('esp', {month : 'short', year:'numeric'}).toLocaleUpperCase()
        lead.origen = (this.origen.find(o => o.val == lead.origen)?.title || null)
        lead.ciudadCat = lead.ciudadCat
        lead.calidad = lead.calidad ? 'Sí' : 'No'
        lead.espacio = lead.espacio != 6 || lead.espacio != 7 ? (this.espacio.find(o => o.val == lead.espacio)?.title) : (lead.ciudadCat || '')
        lead.auxEstado = lead.estado ? this.estado.find(e=>e.val == lead.estado)?.title : null
        lead.auxFechaTransformacion = lead.fechaTransformacion ? new Date(lead.fechaTransformacion).toLocaleDateString('esp', {day:'numeric', month:'short', year :'numeric' }).toLocaleUpperCase() : null
        lead.nota = (lead.motivo || null)
      });
      
      const eliminados = await axios({
        method: "GET",
        url: `${process.env.VUE_APP_API_URL}/leads/eliminados`,
      });
      this.leadsEliminados = eliminados.data
      this.leadsEliminados.forEach(lead => {
        lead.cola = (Math.floor((Math.abs(new Date(lead.fechaTransformacion) - new Date(lead.fecha))) / (1000 * 60 * 60 * 24)))
        lead.mes = new Date(lead.fecha).toLocaleDateString('esp', {month : 'short', year:'numeric'}).toLocaleUpperCase()
        lead.origen = (this.origen.find(o => o.val == lead.origen)?.title || null)
        lead.ciudadCat = lead.ciudadCat
        lead.calidad = lead.calidad ? 'Sí' : 'No'
        lead.espacio = lead.espacio != 6 || lead.espacio != 7 ? (this.espacio.find(o => o.val == lead.espacio)?.title) : (lead.ciudadCat || '')
        lead.auxEstado = lead.estado ? this.estado.find(e=>e.val == lead.estado)?.title : null
        lead.auxFechaTransformacion = lead.fechaTransformacion ? new Date(lead.fechaTransformacion).toLocaleDateString('esp', {day:'numeric', month:'short', year :'numeric' }).toLocaleUpperCase() : null
        lead.nota = (lead.motivo || null)
      });

      this.loading = false
    },
    addLead(){
      this.$router.push({ name: "datosLead" })
    }
  },
};
</script>

<style>
.dark.st-1 {
  position:sticky !important;left: 0px !important;white-space: nowrap !important;background-color: #1e1e1e !important;
}
tr:hover .dark.st-1 {
  position:sticky !important;left: 0px !important;white-space: nowrap !important;background-color: #616161 !important;
}

.dark.st-2 {
  position:sticky !important;left: 46px !important;white-space: nowrap !important;background-color: #1e1e1e !important;
}
tr:hover .dark.st-2 {
  position:sticky !important;left: 46px !important;white-space: nowrap !important;background-color: #616161 !important;
}

.dark.st-3 {
  position:sticky !important;left: 166px !important;white-space: nowrap !important;background-color: #1e1e1e !important;
}
tr:hover .dark.st-3 {
  position:sticky !important;left: 166px !important;white-space: nowrap !important;background-color: #616161 !important;
}

.dark.st-4 {
  position:sticky !important;left: 286px !important;white-space: nowrap !important;background-color: #1e1e1e !important;
}
tr:hover .dark.st-4 {
  position:sticky !important;left: 286px !important;white-space: nowrap !important;background-color: #616161 !important;
}

.dark.st-5 {
  position:sticky !important;left: 406px !important;white-space: nowrap !important;background-color: #1e1e1e !important;
}
tr:hover .dark.st-5 {
  position:sticky !important;left: 406px !important;white-space: nowrap !important;background-color: #616161 !important;
}

.dark.st-6 {
  position:sticky !important;left: 466px !important;white-space: nowrap !important;background-color: #1e1e1e !important;
}
tr:hover .dark.st-6 {
  position:sticky !important;left: 466px !important;white-space: nowrap !important;background-color: #616161 !important;
}

.dark.st-7 {
  position:sticky !important;left: 586px !important;white-space: nowrap !important;background-color: #1e1e1e !important;
}
tr:hover .dark.st-7 {
  position:sticky !important;left: 586px !important;white-space: nowrap !important;background-color: #616161 !important;
}

.dark.st-8 {
  position:sticky !important;left: 736px !important;white-space: nowrap !important;background-color: #1e1e1e !important;
}
tr:hover .dark.st-8 {
  position:sticky !important;left: 736px !important;white-space: nowrap !important;background-color: #616161 !important;
}

.dark.st-9 {
  position:sticky !important;left: 856px !important;white-space: nowrap !important;background-color: #1e1e1e !important;
}
tr:hover .dark.st-9 {
  position:sticky !important;left: 856px !important;white-space: nowrap !important;background-color: #616161 !important;
}

.dark.st-10 {
  position:sticky !important;left: 936px !important;white-space: nowrap !important;background-color: #1e1e1e !important;
}
tr:hover .dark.st-10 {
  position:sticky !important;left: 936px !important;white-space: nowrap !important;background-color: #616161 !important;
}



.light.st-1 {
  position:sticky !important;left: 0px !important;white-space: nowrap !important;background-color: #fff !important;
}
tr:hover .light.st-1 {
  position:sticky !important;left: 0px !important;white-space: nowrap !important;background-color: #eeeeee !important;
}

.light.st-2 {
  position:sticky !important;left: 46px !important;white-space: nowrap !important;background-color: #fff !important;
}
tr:hover .light.st-2 {
  position:sticky !important;left: 46px !important;white-space: nowrap !important;background-color: #eeeeee !important;
}

.light.st-3 {
  position:sticky !important;left: 166px !important;white-space: nowrap !important;background-color: #fff !important;
}
tr:hover .light.st-3 {
  position:sticky !important;left: 166px !important;white-space: nowrap !important;background-color: #eeeeee !important;
}

.light.st-4 {
  position:sticky !important;left: 286px !important;white-space: nowrap !important;background-color: #fff !important;
}
tr:hover .light.st-4 {
  position:sticky !important;left: 286px !important;white-space: nowrap !important;background-color: #eeeeee !important;
}

.light.st-5 {
  position:sticky !important;left: 406px !important;white-space: nowrap !important;background-color: #fff !important;
}
tr:hover .light.st-5 {
  position:sticky !important;left: 406px !important;white-space: nowrap !important;background-color: #eeeeee !important;
}

.light.st-6 {
  position:sticky !important;left: 466px !important;white-space: nowrap !important;background-color: #fff !important;
}
tr:hover .light.st-6 {
  position:sticky !important;left: 466px !important;white-space: nowrap !important;background-color: #eeeeee !important;
}

.light.st-7 {
  position:sticky !important;left: 586px !important;white-space: nowrap !important;background-color: #fff !important;
}
tr:hover .light.st-7 {
  position:sticky !important;left: 586px !important;white-space: nowrap !important;background-color: #eeeeee !important;
}

.light.st-8 {
  position:sticky !important;left: 736px !important;white-space: nowrap !important;background-color: #fff !important;
}
tr:hover .light.st-8 {
  position:sticky !important;left: 736px !important;white-space: nowrap !important;background-color: #eeeeee !important;
}

.light.st-9 {
  position:sticky !important;left: 856px !important;white-space: nowrap !important;background-color: #fff !important;
}
tr:hover .light.st-9 {
  position:sticky !important;left: 856px !important;white-space: nowrap !important;background-color: #eeeeee !important;
}

.light.st-10 {
  position:sticky !important;left: 936px !important;white-space: nowrap !important;background-color: #fff !important;
}
tr:hover .light.st-10 {
  position:sticky !important;left: 936px !important;white-space: nowrap !important;background-color: #eeeeee !important;
}
</style>